@use "sass/forward" as *;

// estilos del carrusel
@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";
// fuentes
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;300;400;800&display=swap");

@keyframes fade {
  to {opacity: 1;}
}

html {

  overflow-x: hidden;
}

body {
  font-family: Raleway;
  position: relative;
  color: #fff;
  font-size: clamp(1rem, 1.2vw, 4rem);
  z-index: -1;
  
  transition: 1s ease-in-out;
  &::after {
    content: '';
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    opacity: 0;
    animation: fade 3s alternate infinite;
  }
}

#cuerpo{
  width: 100%;
  position: relative;
  margin: 0;
  z-index: 100;
  padding:10vh 0;
  @include cel {
    padding-top: 2vh;
    height: auto;
    padding-bottom: 2vh;
  }
  @include tableta {
    padding-top: 2vh;
  }
}
.minmax {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-template-columns: repeat(auto-fit, minmax(max-content, 1fr));
}

.conte {
  width: min(100%, 145rem);
  padding: 0 5rem;
  margin-inline: auto;
  display: flex;
  flex-wrap: wrap;
  min-height: 80vh;

  @include cel {
    margin-top: 2vh;
    width: min(100%, 145rem);
    padding: 0 1rem;
    #izq,
    #der {
      flex: 1 0 auto;
      width: 100% !important;
      text-align: center;
      height: auto !important;
    }
  }
  @include tableta {
    margin-top: 0rem;
    padding: 0 1rem;
    #izq,
    #der {
      flex: 1 0 100% !important;
   
    }
  }
  #izq {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content:center;
    flex: 1 1 auto;
    flex-wrap: wrap;
    width: 30%;
    border: solid 1px $amarillo;
  
    h1 {

      flex: 1 0 100%;
      // background: $amarillo;
      // background-clip: text;
      // -webkit-background-clip: text;
      // color: transparent;
      font-size: clamp(2.5rem, 3vw, 4vw);
      line-height: 1em;
      padding: 2rem;
      @include cel {
        padding-top: 2rem;
      }

    }
    p{
      border-top: solid 1px $amarillo;
      padding: 2rem;
      font-size: clamp(1rem, 1vw, 1.5vw);
      @include cel{
        font-size: 1.3rem;
      }
    }
  }
  #der {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex: 1 1 auto;
    flex-wrap: wrap;
    width: 60%;
    padding: 0 2rem;
    position: relative;
    &:before{
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      // background: $gris;
      // opacity: .2;
    // filter: blur(3px);
    border-right: solid 1px $amarillo;
    border-top: solid 1px $amarillo;
    border-bottom: solid 1px $amarillo;
    
    }
    // filter: blur(8px);
    // -webkit-filter: blur(8px);
    #formu {
      h3 {
        padding: 2rem 0;
      }
   
      form{
        display: flex;
        flex-wrap: wrap;
        gap: .2rem;
        box-sizing: border-box;
        
        input,
        select {
          background: $negro;
          border: none;
          color: $gris;
          font-size: 1.2rem;
          padding: 0.4rem;
          margin: 0.3rem 0;
          flex: 1 0 50;
          @include cel {
            flex: 1 0 100%;
            width: 100%!important;
          }
        }
      }
      #subject {
        flex: 1 0 100%;
      }
      #contactoBtn {
        z-index: 300;
        display: block;
        padding: 0.5rem;
        margin: 0.3rem 0;
        text-align: center;
        text-decoration: none;
        background-color: $verde;
        color: #fff;
        border: none;
        width: 50%;
        font-size: 1.4rem;
        &:hover {
          background-color: $naranja;
          color: $negro;
        }
        @include cel {
          width: 100%;
          font-size: 1rem;
        }
      }
    }
  }
}

#social {
  
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;

  @include cel {
    text-align: center;
    padding-top: 1rem;
  }
  flex: 1 1 100%;
  h4 {
    padding: 0.5rem 0;
    font-weight: 400;
    font-size: 1.1rem;
  }
  img {
    width: 3rem;
    padding: 0.5rem;
  }
  li {
    padding-bottom: 0.3rem;
    a {
      color: #fff;
      text-decoration: underline;
      text-decoration-color: $amarillo;
    }
    address {
      font-style: normal;
      padding-bottom: 2rem;
      a {
        text-decoration: none !important;
      }
    }
  }
}
#msj {
  flex: 1 1 100%;
  color: #fff;
  border-top: solid 1px $gris;
  margin-top: 1rem;
  padding-top: 1rem;
  font-size: 1.4rem;
}
#der{

  #clients{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    li{
      flex: 1 1 16%;
      max-width: 12rem;
      mix-blend-mode: overlay;
      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        padding: 1rem;
      }
    }
    @include tableta{
      margin-top: 3em;
      
    }
    @include cel{
      margin-top: 3em;
      li{
        flex: 1 1 35%;
      }
    }
  }
}

.desactivado {
  pointer-events: none;
  filter: blur(2px);
  opacity: 0.5;
}