@import "https://fonts.googleapis.com/css2?family=Raleway:wght@100;300;400;800&display=swap";
html {
  box-sizing: border-box;
  font-size: 16px;
}

*, :before, :after {
  box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

h1, h2 {
  font-weight: 700;
}

h3 {
  font-weight: 300;
}

ol, ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  color: #fff;
}

nav {
  width: 100%;
  z-index: 900;
  background-blend-mode: luminosity;
  background-color: #ffce19;
  justify-content: space-between;
  align-items: center;
  display: flex;
  overflow: hidden;
}

nav .logo {
  flex: 0 80%;
  padding: 2rem;
}

@media (max-width: 479px) {
  nav .logo {
    flex: 1 0 30%;
  }
}

@media (min-width: 767px) and (max-width: 1023px) {
  nav .logo {
    flex: 0 50%;
  }
}

.anima {
  -o-transition: all .25s ease-in-out;
  transition: all .25s ease-in-out;
}

@keyframes menuAbierto {
  from {
    height: 85px;
  }

  to {
    height: 100vh;
  }
}

@keyframes menuCerrado {
  from {
    height: 100vh;
  }

  to {
    height: 85px;
  }
}

.menuFullAbierto {
  animation: menuAbierto 1.2s forwards;
}

.menuFullAbierto ul {
  display: block;
  visibility: visible !important;
  opacity: 1 !important;
}

.desanimaMenu {
  animation: menuCerrado 1s backwards;
}

.glide {
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

.glide * {
  box-sizing: inherit;
}

.glide__track {
  overflow: hidden;
}

.glide__slides {
  width: 100%;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  white-space: nowrap;
  will-change: transform;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  position: relative;
  overflow: hidden;
}

.glide__slides--dragging {
  -webkit-user-select: none;
  user-select: none;
}

.glide__slide {
  width: 100%;
  height: 100%;
  white-space: normal;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  flex-shrink: 0;
}

.glide__slide a {
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

.glide__arrows, .glide__bullets {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.glide--rtl {
  direction: rtl;
}

@keyframes fade {
  to {
    opacity: 1;
  }
}

html {
  overflow-x: hidden;
}

body {
  color: #fff;
  z-index: -1;
  font-family: Raleway;
  font-size: clamp(1rem, 1.2vw, 4rem);
  transition: all 1s ease-in-out;
  position: relative;
}

body:after {
  content: "";
  opacity: 0;
  animation: fade 3s infinite alternate;
  position: absolute;
  inset: 0;
}

#cuerpo {
  width: 100%;
  z-index: 100;
  margin: 0;
  padding: 10vh 0;
  position: relative;
}

@media (max-width: 479px) {
  #cuerpo {
    height: auto;
    padding-top: 2vh;
    padding-bottom: 2vh;
  }
}

@media (min-width: 767px) and (max-width: 1023px) {
  #cuerpo {
    padding-top: 2vh;
  }
}

.minmax {
  grid-template-columns: repeat(auto-fit, minmax(max-content, 1fr));
  display: grid;
}

.conte {
  width: min(100%, 145rem);
  min-height: 80vh;
  flex-wrap: wrap;
  margin-inline: auto;
  padding: 0 5rem;
  display: flex;
}

@media (max-width: 479px) {
  .conte {
    width: min(100%, 145rem);
    margin-top: 2vh;
    padding: 0 1rem;
  }

  .conte #izq, .conte #der {
    text-align: center;
    flex: 1 0 auto;
    width: 100% !important;
    height: auto !important;
  }
}

@media (min-width: 767px) and (max-width: 1023px) {
  .conte {
    margin-top: 0;
    padding: 0 1rem;
  }

  .conte #izq, .conte #der {
    flex: 1 0 100% !important;
  }
}

.conte #izq {
  width: 30%;
  border: 1px solid #ffce19;
  flex-wrap: wrap;
  flex: auto;
  place-content: center;
  align-items: center;
  display: flex;
}

.conte #izq h1 {
  flex: 1 0 100%;
  padding: 2rem;
  font-size: max(2.5rem, 3vw);
  line-height: 1em;
}

@media (max-width: 479px) {
  .conte #izq h1 {
    padding-top: 2rem;
  }
}

.conte #izq p {
  border-top: 1px solid #ffce19;
  padding: 2rem;
  font-size: max(1rem, 1vw);
}

@media (max-width: 479px) {
  .conte #izq p {
    font-size: 1.3rem;
  }
}

.conte #der {
  width: 60%;
  flex-wrap: wrap;
  flex: auto;
  place-content: center;
  align-items: center;
  padding: 0 2rem;
  display: flex;
  position: relative;
}

.conte #der:before {
  content: "";
  width: 100%;
  height: 100%;
  border-top: 1px solid #ffce19;
  border-bottom: 1px solid #ffce19;
  border-right: 1px solid #ffce19;
  position: absolute;
  top: 0;
  left: 0;
}

.conte #der #formu h3 {
  padding: 2rem 0;
}

.conte #der #formu form {
  box-sizing: border-box;
  flex-wrap: wrap;
  gap: .2rem;
  display: flex;
}

.conte #der #formu form input, .conte #der #formu form select {
  color: #7b7c7f;
  background: #000;
  border: none;
  flex: 1 0 50px;
  margin: .3rem 0;
  padding: .4rem;
  font-size: 1.2rem;
}

@media (max-width: 479px) {
  .conte #der #formu form input, .conte #der #formu form select {
    flex: 1 0 100%;
    width: 100% !important;
  }
}

.conte #der #formu #subject {
  flex: 1 0 100%;
}

.conte #der #formu #contactoBtn {
  z-index: 300;
  text-align: center;
  color: #fff;
  width: 50%;
  background-color: #0f9479;
  border: none;
  margin: .3rem 0;
  padding: .5rem;
  font-size: 1.4rem;
  text-decoration: none;
  display: block;
}

.conte #der #formu #contactoBtn:hover {
  color: #000;
  background-color: #d86d0f;
}

@media (max-width: 479px) {
  .conte #der #formu #contactoBtn {
    width: 100%;
    font-size: 1rem;
  }
}

#social {
  flex-direction: column;
  flex: 100%;
  font-size: .8rem;
  display: flex;
}

@media (max-width: 479px) {
  #social {
    text-align: center;
    padding-top: 1rem;
  }
}

#social h4 {
  padding: .5rem 0;
  font-size: 1.1rem;
  font-weight: 400;
}

#social img {
  width: 3rem;
  padding: .5rem;
}

#social li {
  padding-bottom: .3rem;
}

#social li a {
  color: #fff;
  text-decoration: underline #ffce19;
}

#social li address {
  padding-bottom: 2rem;
  font-style: normal;
}

#social li address a {
  text-decoration: none !important;
}

#msj {
  color: #fff;
  border-top: 1px solid #7b7c7f;
  flex: 100%;
  margin-top: 1rem;
  padding-top: 1rem;
  font-size: 1.4rem;
}

#der #clients {
  flex-wrap: wrap;
  gap: 1rem;
  display: flex;
}

#der #clients li {
  max-width: 12rem;
  mix-blend-mode: overlay;
  flex: 16%;
}

#der #clients li img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 1rem;
}

@media (min-width: 767px) and (max-width: 1023px) {
  #der #clients {
    margin-top: 3em;
  }
}

@media (max-width: 479px) {
  #der #clients {
    margin-top: 3em;
  }

  #der #clients li {
    flex: 35%;
  }
}

.desactivado {
  pointer-events: none;
  filter: blur(2px);
  opacity: .5;
}

/*# sourceMappingURL=index.f1370ca0.css.map */
